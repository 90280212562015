@import "../../styles/theme.scss";
@import "../../styles/viewports.scss";

.banner-container {
  background: linear-gradient(
    98.23deg,
    #061121 59.71%,
    #009be0 92.83%,
    #ffffff 109.89%
  );

  padding-top: 40px;
  padding-bottom: 48px;
  padding-left: 10%;
  padding-right: 10%;
  @include breakpoint(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include breakpoint(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.veno {
    background: linear-gradient(
      360deg,
      rgba(0, 155, 224, 0.5) 0%,
      rgba(0, 155, 224, 0.2) 100%
    );
  }

  &.cdc {
    background: radial-gradient(122.38% 121.07% at -12.94% -50.59%, #45FFF2 -10%, rgba(0, 45, 116, 0) 100%), linear-gradient(0deg, #152C4B 0%, #081020 100%);
    padding-bottom: 56px;
  }
}
