.link {
  color: white;
  text-decoration: none;
  font-family: "eurostile-extended", "roboto", sans-serif;
  cursor: pointer;

  &.active {
    font-weight: 500;
  }
}

.isMdDown {
  padding: 24px;
  width: 240px;

  > .logo {
    margin-bottom: 40px;
  }
}
