@import "../styles/theme.scss";
@import "../styles/viewports";

button.button {
  border: none;
  color: $text-white;
  font-weight: $medium;
  font-size: 16px;
  letter-spacing: 0.02em;
  cursor: pointer;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 46px;

  @include breakpoint(sm) {
    height: 37px;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
  }

  a {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: none;
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.primary {
    background: $primary;

    &:enabled:hover,
    &:enabled:focus {
      opacity: 0.85;
      box-shadow: var(--shadow-hover);
    }
  }

  &.outline {
    background-color: transparent;
    border-color: $primary;
    border-width: 1px;
    border-style: solid;
    color: $text-white;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
      border-color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover {
      background-color: $primary;
      box-shadow: var(--shadow-hover);
    }
    &:enabled:focus {
      background-color: transparent;
    }
  }

  &.secondary {
    background-color: var(--secondary-button);
    color: var(--text-secondary-button);
    font-weight: $normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-blue;
      color: $text-white;
      font-weight: $bold;
      box-shadow: var(--shadow-hover);
    }
  }

  &.ternary {
    color: var(--text);
    background: var(--emphasis-1);
    border: 1px solid var(--emphasis-1);
    font-weight: normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--emphasis-2);
    }
  }

  &.ghost {
    color: var(--text);
    background: var(--background);
    border: 1px solid var(--emphasis-1);
    font-weight: normal;

    &:disabled {
      cursor: not-allowed;
      color: var(--text-secondary-button-disabled);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: var(--emphasis-1);
    }
  }

  &.temporary {
    background: $yellow;
    color: $black;

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.2);
    }

    &:enabled:hover,
    &:enabled:focus {
      background-color: $primary-blue;
      color: $text-white;
      box-shadow: var(--shadow-hover);
    }
  }

  &.cancel {
    background: $white;

    &:enabled:hover,
    &:enabled:focus {
      color: $primary-blue;
    }
  }

  &.large {
    width: 176px;
    min-height: 40px;

    @include breakpoint(md) {
      width: 140px;
    }
  }

  &.small {
    height: 37px;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
  }

  &.fullWidth {
    width: 100%;
  }
}
