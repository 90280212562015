@import "../styles/theme.scss";

.component-text {
  &.gradient1 {
    background: $gradient1;
  }

  &.gradient2 {
    background: $gradient2;
  }

  &.gradient3 {
    background: $gradient3;
  }

  &.gradient-orange {
    background: $gradient-orange;
  }

  &.gradient-blue {
    background: $gradient-blue;
  }

  &.dim {
    color: $gray;
  }
}
