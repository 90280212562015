$text-red: #f53535;
$text-black: #000000;
$text-white: #ffffff;

$primary: #007bb2;
$primary40T: #{$primary}40;
$primary-blue: #007bb2;
$primary-blue-light: #f5f2fd;
$primary-blue-dark: #0d0033;

$black80: #333333;
$black30: #a5a5a5;
$error: #d9475a;
$yellow2: #ffd848;
$yellow55: #{$yellow2}8c;
$light-blue: #e8e5fb;
$dark-purple: #110040;
$blue: #007bb2;
$indigo20T: #{$primary-blue}33;
$blue40T: #{$blue}66;
$blue80T: #009be0cc;
$yellow: #f2d35f;
$indigo1: #4b11f2;
$indigo2: #311188;
$indigo3: #121334;
$indigo4: #070713;
$sunset: #d96a3b;
$sunburn: #e6ad76;
$sand1: #e3d899;
$sand2: #faf3ce;
$sand3: #fdfdf8;

$divider-red: #fb5a5a;

$disabled: #aaaeb2;

// Gradients
$gradient1: linear-gradient(
  90deg,
  #009be0 0%,
  rgba(255, 255, 255, 0.5) 51.04%,
  #e9540e 100%
);
$gradient2: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0) 0%,
  #009be0 27.08%,
  #ffffff 51.04%,
  #e9540e 73.96%,
  rgba(0, 0, 0, 0) 100%
);
$gradient3: linear-gradient(90deg, #009be0 0%, #ffffff 51.04%, #e9540e 100%);
$gradient-blue: linear-gradient(90deg, #009be0 0%, rgba(0, 155, 224, 0.5) 100%);
$gradient-blue-toast: linear-gradient(
  98.23deg,
  #061121 59.71%,
  #009be0 92.83%,
  #ffffff 109.89%
);
$gradient-orange: linear-gradient(
  90deg,
  #e9540e 0%,
  rgba(233, 84, 14, 0.5) 100%
);
$gradient-green-toast: linear-gradient(
  180deg,
  rgba(0, 166, 140, 0.5) 0%,
  #00a68c 100%
);
$gradient-red-toast: linear-gradient(
  180deg,
  rgba(217, 71, 73, 0.5) 0%,
  #d9475a 100%
);
$gradient-primary-blue-toast: linear-gradient(
  360deg,
  rgba(0, 155, 224, 0.5) 0%,
  rgba(0, 155, 224, 0.2) 100%
);

// Gray scales
$black: #000000;
$black50: #0d0d0d;
$darkest-gray: #1a1a1a;
$darker-gray: #292929;
$dark-gray: #505050;
$gray: #adadad;
$light-gray: #d8d8d8;
$white: #ffffff;
$white10: #ffffff1a;
$white20: #ffffff33;
$white30: #4c4c4c;

// Fonts
$font-family: "roboto", monospace;
$font-family-title: "eurostile-extended", "roboto", sans-serif;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$smallFont: 12px;

$shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
$shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
