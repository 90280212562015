@import "../../styles/theme";
@import "../../styles/viewports";

div.cronosUpgradeWarningPage {
  color: var(--text);
  font-size: 16px;
  height: 100%;
  color: $white;

  button:hover {
    cursor: pointer;
  }

  .logoBox {
    position: absolute;
    top: 16px;
  }

  .announcementsBox {
    position: absolute;
    bottom: 16px;
    font-family: "roboto", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
  }

  .contentBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 64px);
    max-width: 565px;
    margin: auto;
    text-align: center;

    .title {
      font-family: "eurostile-extended", "roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 29px;
      line-height: 35px;
    }

    .description {
      font-family: "roboto", sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 26px;
    }

    .buttonBox {
      margin-top: 14px;
    }
  }
}
