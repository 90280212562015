@import "../styles/theme.scss";
@import "../styles/viewports";

.Toastify__toast-container.ferro-toast-container {
  width: auto;
  max-width: 440px;

  .Toastify__toast.ferro-toast {
    background: $gradient-blue-toast;
    border-radius: 10px;
    padding: 0;
    display: block;

    @include breakpoint(sm) {
      margin-left: 1rem;
      top: 4rem;
    }

    > button {
      position: absolute;
      top: 12px;
      right: 14px;
    }

    > .Toastify__toast-body {
      padding: 0;
    }

    .toast {
      display: flex;

      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
      }

      &.toast-success {
        .icon-box {
          background: $gradient-green-toast;
        }
      }

      &.toast-error {
        .icon-box {
          background: $gradient-red-toast;
        }
      }

      &.toast-loading {
        .icon-box {
          background: $gradient-primary-blue-toast;
        }
      }

      .content-box {
        padding: 8px 16px;
        margin-left: 2px;
        flex: 1;

        a {
          text-decoration: none;
        }
      }
    }
  }
}
