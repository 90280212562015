@import "../styles/viewports.scss";

.outContainer {
  @include breakpoint(xl) {
    padding-bottom: 40px;
  }
  @include breakpoint(md) {
    padding-bottom: 80px;
  }
  @include breakpoint(sm) {
    padding-bottom: 360px;
  }
}

.container {
  position: relative;
  min-height: 80vh;
}

.title {
  font-family: "eurostile-extended", "roboto", sans-serif;
  font-weight: 500;
  position: absolute;

  top: 12.8%;
  left: 9.1%;
  font-size: 50px;
  line-height: 60px;
  @include breakpoint(lg) {
    left: 2.6%;
    font-size: 40px;
    line-height: 52px;
  }
  @include breakpoint(sm) {
    top: 10.1%;
    left: 5%;
    font-size: 20px;
    line-height: 26px;
  }
}

.subtitle {
  font-family: "eurostile-extended", "roboto", sans-serif;
  font-weight: 500;
  position: absolute;
  text-align: right;
  z-index: 10;

  top: 60.7%;
  right: 9.1%;
  font-size: 29px;
  line-height: 35px;
  @include breakpoint(lg) {
    top: 48.9%;
    right: 3.9%;
  }
  @include breakpoint(sm) {
    top: 36.6%;
    right: 6.1%;
    font-size: 20px;
    line-height: 26px;
  }

  .security {
    font-family: "eurostile-extended", "roboto", sans-serif;
    font-weight: 500;
    line-height: 14.4px;
    font-size: 14px;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include breakpoint(sm) {
      font-size: 12px;
    }
  }

  img {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.introContainer {
  @include breakpoint(lg) {
    position: absolute;
    top: 67.2%;
    width: 100%;
  }
  @include breakpoint(sm) {
    top: 60.3%;
  }
}

.intro {
  padding: 16px 8px;
  width: 25%;
  font-size: 18px;
  line-height: 26px;
  position: absolute;
  left: 10.3%;
  top: 79.5%;
  @include breakpoint(xl) {
    font-size: 16px;
  }
  @include breakpoint(lg) {
    font-size: 18px;
    width: 90.9%;
    position: relative;
    left: 4%;
  }
  @include breakpoint(sm) {
    font-size: 16px;
  }
}

.detail {
  height: 136px;
  width: 56.7%;
  position: absolute;
  left: 35.8%;
  top: 79.5%;
  display: flex;
  justify-content: space-between;

  @include breakpoint(lg) {
    left: 3%;
    width: 93%;
    position: relative;
  }

  @include breakpoint(sm) {
    height: initial;
    // top: 61.5%;
    left: 0;
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }
}

.info {
  margin: 16px 20px;
  text-align: right;
  width: 300px;

  @include breakpoint(sm) {
    width: auto;
  }

  > h5 {
    font-family: "eurostile-extended", "roboto", sans-serif;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  > .description {
    font-size: 16px;
    color: #adadad;
  }
}
